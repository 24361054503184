import { matchStyle } from 'BreetHelpers';
import { useClickOutside } from 'BreetHooks';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import type { PopoverBehaviourType, PopoverPositionType, PopoverProps } from './PopoverProps';

const DefaultContent = () => (
	<div style={{ color: 'black', background: 'white', padding: '1.4rem' }}>
		Lorem ipsum is placeholder text layouts and visual mockups.Lorem ipsum is placeholder text layouts and visual mockups.Lorem
		ipsum is placeholder text layouts and visual mockups.
	</div>
);

/**
 * Popover Component
 *
 * A reusable Popover component that displays content in a popover positioned relative to a trigger element.
 *
 * @component
 * @example
 * <Popover
 *   trigger={<button>Click me</button>}
 *   renderContent={<div>Popover content</div>}
 *   position="bottom"
 * />
 */
const Popover = ({
	disabled, // Disable click events of the popover
	trigger, // The trigger element that opens the popover
	isOpen = false, // Whether the popover should be open initially
	triggerManually = false, // Whether the popover should be triggered manually
	renderContent = <DefaultContent />, // The content to render inside the popover
	position = 'bottomLeft', // The position of the popover relative to the trigger
	offsetY = 0, // Vertical offset from the trigger
	offsetX = 0, // Horizontal offset from the trigger
	behavior = 'click', // The behavior that opens the popover ('click' or 'hover')
	withCaret = false, // Whether to show a caret on the trigger
	withMask = false, // Whether to show a mask behind the popover
	className, // Additional CSS classes to apply to the popover
	onClickOutside, // Callback function when clicking outside the popover
}: PopoverProps) => {
	const [isMounted, setIsMounted] = useState<boolean>(false);
	const [isClicked, setIsClicked] = useState<boolean>(false);
	const [popoverRect, setPopoverRect] = useState<DOMRect | undefined>(undefined);

	// Refs for Popover DOM elements
	const containerRef = useRef<HTMLDivElement>(null);
	const popoverContainRef = useRef<HTMLDivElement>(null);
	const triggeref = useRef<HTMLDivElement>(null);

	const triggerRect = triggeref.current?.getBoundingClientRect();

	const handleOutsideClick = useCallback(() => {
		if (onClickOutside) onClickOutside();
		setIsClicked(false);
	}, [onClickOutside]);

	useClickOutside({
		targetElement: popoverContainRef,
		onClickOutsideClick: handleOutsideClick,
	});

	useEffect(() => {
		if (triggerManually) {
			setIsClicked(isOpen);
		}
	}, [isOpen, triggerManually]);

	// Update the popoverRect state when the popover is opened or closed
	useEffect(() => {
		setPopoverRect(popoverContainRef.current?.getBoundingClientRect());
	}, [isClicked]);

	useLayoutEffect(() => {
		setIsMounted(true);
	}, []);

	const popoverOpenStateClassName = matchStyle(isClicked ? 'clicked' : 'default', {
		clicked: ' bt_popover_is-open',
		default: '',
	});

	const customClassName = matchStyle(className, {
		[`${className}`]: ` ${className}`,
		default: '',
	});

	// Memoized function to calculate the popover's position based on the given position type
	// ?**: to be done later
	//  - Observe the position of the popover
	//  - Switch the popover opposite direction vertically | horizontally
	//    when the popover is close to the edge of the window
	const getPosition = useCallback(
		(pos: PopoverPositionType) => () => {
			if (!triggerRect || !popoverRect) return {};

			const windowWidth = window.innerWidth;
			const windowHeight = window.innerHeight;

			const commonBottomStyles = {
				top: triggerRect.bottom + offsetY,
				left: triggerRect.left + offsetX,
			};

			const commonTopStyles = {
				top: triggerRect.top - popoverRect.height - offsetY,
				left: Math.max(triggerRect.left + (triggerRect.width / 2 - popoverRect.width / 2) + offsetX, 10),
			};

			const isBottomPositionOutOfBounds = () => commonBottomStyles.top + triggerRect.height + popoverRect.height > windowHeight;
			const isTopPositionOutOfBounds = () => commonTopStyles.top < 0;
			const isLeftPositionOutOfBounds = () => commonBottomStyles.left < 0;
			const isRightPositionOutOfBounds = () => commonBottomStyles.left + popoverRect.width > windowWidth;

			const getTopPosition = () => ({
				top: isTopPositionOutOfBounds() ? `${commonBottomStyles.top}px` : `${commonTopStyles.top}px`,
				left: `${commonTopStyles.left}px`,
			});

			const getTopLeftPosition = () => ({
				top: isTopPositionOutOfBounds() ? `${commonBottomStyles.top}px` : `${commonTopStyles.top}px`,
				left: `${triggerRect.left + offsetX}px`,
			});

			const getTopRightPosition = () => ({
				top: isTopPositionOutOfBounds() ? `${commonBottomStyles.top}px` : `${commonTopStyles.top}px`,
				left: `${triggerRect.right - popoverRect.width + offsetX}px`,
			});

			const getBottomPosition = () => ({
				top: isBottomPositionOutOfBounds() ? `${commonTopStyles.top}px` : `${commonBottomStyles.top}px`,
				left: triggerRect.left + (triggerRect.width / 2 - popoverRect.width / 2) + offsetX,
			});

			const getBottomLeftPosition = () => ({
				top: isBottomPositionOutOfBounds() ? `${commonTopStyles.top}px` : `${commonBottomStyles.top}px`,
				left: `${triggerRect.left + offsetX}px`,
			});

			const getBottomRightPosition = () => ({
				top: isBottomPositionOutOfBounds() ? `${commonTopStyles.top}px` : `${commonBottomStyles.top}px`,
				left: `${triggerRect.right - popoverRect.width + offsetX}px`,
			});

			const getLeftPosition = () => ({
				top: `${triggerRect.top + (triggerRect.height / 2 - popoverRect.height / 2)}px`,
				left: isLeftPositionOutOfBounds()
					? `${triggerRect.right + offsetX}px`
					: `${triggerRect.left - popoverRect.width + offsetX}px`,
			});

			const getRightPosition = () => ({
				top: `${triggerRect.top + (triggerRect.height / 2 - popoverRect.height / 2)}px`,
				left: isRightPositionOutOfBounds()
					? `${triggerRect.left - popoverRect.width + offsetX}px`
					: `${triggerRect.right + offsetX}px`,
			});

			const positionMap = {
				top: getTopPosition,
				topLeft: getTopLeftPosition,
				topRight: getTopRightPosition,
				bottom: getBottomPosition,
				bottomLeft: getBottomLeftPosition,
				bottomRight: getBottomRightPosition,
				left: getLeftPosition,
				right: getRightPosition,
			};

			return positionMap[pos]();
		},
		[offsetX, offsetY, popoverRect, triggerRect]
	);

	// Update the popover position on window resize
	useEffect(() => {
		const handleResize = () => {
			getPosition(position);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [getPosition, position]);

	useEffect(() => {
		// Added click functionality to the trigger wrapper manually
		const currentDiv = triggeref.current;
		if (currentDiv) {
			const handleClick = () => {
				if (disabled) return;
				setIsClicked((prev) => !prev);
			};

			currentDiv.addEventListener('click', handleClick);

			return () => {
				currentDiv.removeEventListener('click', handleClick);
			};
		}
	}, [disabled, isMounted]);

	const constructProps = (behave: PopoverBehaviourType) => {
		if (behave === 'hover') {
			return {
				className: `bt_popover_container  bt_popover__behavior_hover${customClassName}`,
			};
		}

		return {
			className: `bt_popover_container bt_popover__behavior_click${customClassName}`,
		};
	};

	const behaviorProps = constructProps(behavior);

	const modalEl = document.getElementById('bt_modal_root');
	if (!modalEl) return null;

	return (
		<div
			{...behaviorProps}
			ref={containerRef}
		>
			{withMask && <div className={`bt_popover_mask${popoverOpenStateClassName}`} />}

			{isClicked &&
				isMounted &&
				createPortal(
					<div
						ref={popoverContainRef}
						className={`bt_popover_popoverwrap${customClassName}`}
						style={{ ...getPosition(position)(), position: 'fixed' }}
					>
						<div
							className={`bt_popover_popoverwrap_content bt_popover_popoverwrap_position_${position}${popoverOpenStateClassName}`}
						>
							{renderContent}
						</div>
					</div>,
					modalEl
				)}
			<div
				ref={triggeref}
				className='bt_popover__trigger'
			>
				<span> {isMounted ? trigger : null}</span>
				{withCaret ? <span className={`bt_popover__trigger_icon${isClicked ? ' isOpen' : ''}`}>V</span> : null}
			</div>
		</div>
	);
};

export default Popover;
