export interface RouteListItem {
	linkRef: string;
	linkTitle: string;
	pageTitle?: string;
}

export type RouteParamsType =
	| {
			txReference: string;
			txStatus: string;
			adminId: string;
			userId: string;
			fiatId: string;
			cryptoWalletId: string;
			accountId: string;
			referralId: string;
			actionId: string;
			screeningId: string;
			suggestionId: string;
			kycId: string;
	  }
	| Record<string, string | undefined>;

export const withdrawalsRouteList: RouteListItem[] = [
	{
		linkRef: '/withdrawals/ngn',
		linkTitle: 'Naira Requests',
	},
	{
		linkRef: '/withdrawals/ghs',
		linkTitle: 'Cedis Requests',
	},
];

export const withdrawalsSubRouteList: RouteListItem[] = [
	{
		linkRef: '/withdrawals/ngn/:txReference/:txStatus',
		linkTitle: ':txStatus NGN Withdrawal',
	},
	{
		linkRef: '/withdrawals/ghs/:txReference/:txStatus',
		linkTitle: ':txStatus GHS Withdrawal',
	},
];

export const userMgtRouteList: RouteListItem[] = [
	{
		linkRef: '/user-management/users',
		linkTitle: 'Users',
	},
	{
		linkRef: '/user-management/kyc',
		linkTitle: 'User KYC',
	},
	{
		linkRef: '/user-management/aml-screening',
		linkTitle: 'AML Screening',
	},
	{
		linkRef: '/user-management/bank-accounts',
		linkTitle: 'Bank Accounts',
	},
	{
		linkRef: '/user-management/crypto-wallets',
		linkTitle: 'Crypto Wallets',
	},
	{
		linkRef: '/user-management/user-wallet-balance',
		linkTitle: 'User Wallet Balance',
	},
	{
		linkRef: '/user-management/point-reward-log',
		linkTitle: 'Points Log',
	},
	{
		linkRef: '/user-management/referrals',
		linkTitle: 'Referrals',
	},
];

export const userMgtSubRouteList: RouteListItem[] = [
	{
		linkRef: '/user-management/users/:userId/details',
		linkTitle: 'Details',
		pageTitle: 'User Details',
	},
	{
		linkRef: '/user-management/users/:userId/summary',
		linkTitle: 'Summary',
		pageTitle: 'User Summary',
	},
	{
		linkRef: '/user-management/users/:userId/fiat-wallet',
		linkTitle: 'Fiat Wallet',
		pageTitle: 'User Fiat Wallet',
	},
	{
		linkRef: '/user-management/users/:userId/crypto-wallet',
		linkTitle: 'Crypto Wallet',
		pageTitle: 'User Crypto Wallet',
	},
	{
		linkRef: '/user-management/users/:userId/accounts',
		linkTitle: 'Accounts',
		pageTitle: 'User Accounts',
	},
	{
		linkRef: '/user-management/users/:userId/pr-logs',
		linkTitle: 'PR Logs',
		pageTitle: 'User PR Logs',
	},
	{
		linkRef: '/user-management/users/:userId/ngn-withdrawals',
		linkTitle: 'NGN Withdrawals',
		pageTitle: 'User NGN Withdrawals',
	},
	{
		linkRef: '/user-management/users/:userId/ghs-withdrawals',
		linkTitle: 'GHS Withdrawals',
		pageTitle: 'User GHS Withdrawals',
	},
	{
		linkRef: '/user-management/users/:userId/bills',
		linkTitle: 'Bills',
		pageTitle: 'User Bills',
	},
	{
		linkRef: '/user-management/users/:userId/invoices',
		linkTitle: 'Invoices',
		pageTitle: 'User Invoices',
	},
	{
		linkRef: '/user-management/users/:userId/crypto-trades',
		linkTitle: 'Crypto Trades',
		pageTitle: 'User Crypto Trades',
	},
	{
		linkRef: '/user-management/users/:userId/swap-crypto',
		linkTitle: 'Swap Crypto',
		pageTitle: 'User Swap Crypto',
	},
	{
		linkRef: '/user-management/users/:userId/referrals',
		linkTitle: 'Referrals',
		pageTitle: 'Referrals',
	},
	{
		linkRef: '/user-management/users/:userId/note-history',
		linkTitle: 'Note History',
		pageTitle: 'User Note History',
	},
];

export const userMgtMiscSubRouteList: RouteListItem[] = [
	{
		linkRef: '/user-management/users/:userId/edit',
		linkTitle: 'Edit User',
		pageTitle: 'User Edit User',
	},
	{
		linkRef: '/user-management/users/:userId/fiat-wallet/:fiatId',
		linkTitle: 'Fiat Wallet',
		pageTitle: 'User Fiat Details',
	},
	{
		linkRef: '/user-management/users/:userId/crypto-wallet/:cryptoWalletId',
		linkTitle: 'Crypto Wallet',
		pageTitle: 'Crypto Wallet Details',
	},
	{
		linkRef: '/user-management/users/:userId/accounts/:accountId',
		linkTitle: 'Account',
		pageTitle: 'User Account Details',
	},
	{
		linkRef: '/user-management/users/:userId/pr-logs/:txReference',
		linkTitle: 'PR Logs',
		pageTitle: 'User PR Log Details',
	},
	{
		linkRef: '/user-management/users/:userId/ngn-withdrawals/:txReference',
		linkTitle: 'NGN Withdrawals',
		pageTitle: 'User NGN Withdrawal Details',
	},
	{
		linkRef: '/user-management/users/:userId/ghs-withdrawals/:txReference',
		linkTitle: 'GHS Withdrawals',
		pageTitle: 'User GHS Withdrawal Details',
	},
	{
		linkRef: '/user-management/users/:userId/bills/:txReference',
		linkTitle: 'Bills',
		pageTitle: 'User Bill Details',
	},
	{
		linkRef: '/user-management/users/:userId/invoices/:txReference',
		linkTitle: 'Invoices',
		pageTitle: 'User Invoice Details',
	},
	{
		linkRef: '/user-management/users/:userId/crypto-trades/:txReference',
		linkTitle: 'Crypto Trades',
		pageTitle: 'User Crypto Trade Details',
	},
	{
		linkRef: '/user-management/users/:userId/swap-crypto/:txReference',
		linkTitle: 'Swap Crypto',
		pageTitle: 'User Swap Crypto Details',
	},
	{
		linkRef: '/user-management/users/:userId/referrals/:referralId',
		linkTitle: 'Referrals',
		pageTitle: 'Referral Details',
	},
	{
		linkRef: '/user-management/users/:userId/actions/:actionId',
		linkTitle: 'Actions',
		pageTitle: 'Action Details',
	},
	{
		linkRef: '/user-management/kyc/:kycId',
		linkTitle: 'KYC',
		pageTitle: 'KYC Details',
	},
	{
		linkRef: '/user-management/aml-screening/:screeningId',
		linkTitle: 'AML Screening',
		pageTitle: 'Screening Details',
	},
	{
		linkRef: '/user-management/bank-accounts/:accountId',
		linkTitle: 'Bank Accounts',
		pageTitle: 'Bank Account Details',
	},
	{
		linkRef: '/user-management/crypto-wallets/:cryptoWalletId',
		linkTitle: 'Crypto Wallets',
		pageTitle: 'Crypto Wallets Details',
	},
	{
		linkRef: '/user-management/user-wallet-balance/:userId',
		linkTitle: 'User Wallet Balance',
		pageTitle: 'User Wallet Balance Details',
	},
	{
		linkRef: '/user-management/point-reward-log/:txReference',
		linkTitle: 'Points Log',
		pageTitle: 'Log Details',
	},
	{
		linkRef: '/user-management/referrals/:referralId',
		linkTitle: 'Referral Details',
	},
];

export const accessMgtRouteList: RouteListItem[] = [
	{
		linkRef: '/access-management/create-admin',
		linkTitle: 'Create Admin',
	},
	{
		linkRef: '/access-management/:adminId/view',
		linkTitle: 'Admin Details',
	},
	{
		linkRef: '/access-management/:adminId/edit',
		linkTitle: 'Edit Admin',
	},
	{
		linkRef: '/access-management/:adminId/edit-privilege',
		linkTitle: 'Edit Privilege',
	},
];

export const siteMgtRouteList: RouteListItem[] = [
	{
		linkRef: '/site-management/wallet-and-withdrawal',
		linkTitle: 'Wallet and Withdrawal',
		pageTitle: 'Wallet and Withdrawal Settings',
	},
	{
		linkRef: '/site-management/ngn-banks',
		linkTitle: 'NGN Banks',
		pageTitle: 'NGN Banks',
	},
	{
		linkRef: '/site-management/rate',
		linkTitle: 'Rate Management',
		pageTitle: 'Rate Management',
	},
	{
		linkRef: '/site-management/crypto',
		linkTitle: 'Crypto',
		pageTitle: 'Crypto Settings',
	},
	{
		linkRef: '/site-management/user-address',
		linkTitle: 'User Address',
		pageTitle: 'User Address Settings',
	},
	{
		linkRef: '/site-management/bills',
		linkTitle: 'Bills',
		pageTitle: 'Bills Settings',
	},
	{
		linkRef: '/site-management/invoice',
		linkTitle: 'Invoice',
		pageTitle: 'Invoice Settings',
	},
	{
		linkRef: '/site-management/reward',
		linkTitle: 'Reward',
		pageTitle: 'Reward Settings',
	},
	{
		linkRef: '/site-management/referral',
		linkTitle: 'Referral',
		pageTitle: 'Referral Settings',
	},
	{
		linkRef: '/site-management/kyc',
		linkTitle: 'KYC',
		pageTitle: 'KYC Settings',
	},
	{
		linkRef: '/site-management/other',
		linkTitle: 'Other Settings',
		pageTitle: 'Other Settings',
	},
	{
		linkRef: '/site-management/flash-notes',
		linkTitle: 'Flash/Notes',
		pageTitle: 'Flash/Notes Settings',
	},
	{
		linkRef: '/site-management/cms',
		linkTitle: 'CMS',
		pageTitle: 'CMS Settings',
	},
	{
		linkRef: '/site-management/activity-logs',
		linkTitle: 'Activity Logs',
		pageTitle: 'Activity Logs',
	},
];

export const allRouteListItems: RouteListItem[] = [
	{
		linkRef: '/dashboard',
		linkTitle: 'Dashboard',
	},
	{
		linkRef: '/trades',
		linkTitle: 'Trades',
	},
	{
		linkRef: '/trades/:txReference',
		linkTitle: 'Trade Details',
	},
	{
		linkRef: '/swap-crypto',
		linkTitle: 'Swap Crypto',
	},
	{
		linkRef: '/swap-crypto/:txReference',
		linkTitle: 'Swap Crypto Details',
	},
	{
		linkRef: '/access-management',
		linkTitle: 'Access Management',
	},
	{
		linkRef: '/user-management',
		linkTitle: 'User Management',
	},
	{
		linkRef: '/bills',
		linkTitle: 'Bills',
	},
	{
		linkRef: '/bills/:txReference',
		linkTitle: 'Bill Details',
	},
	{
		linkRef: '/invoices',
		linkTitle: 'Invoices',
	},
	{
		linkRef: '/invoices/:txReference',
		linkTitle: 'Invoice Details',
	},
	...withdrawalsRouteList,
	...withdrawalsSubRouteList,
	...accessMgtRouteList,
	...siteMgtRouteList,
	...userMgtRouteList,
	...userMgtSubRouteList,
	...userMgtMiscSubRouteList,
];

export const sanitizeRouteParams = (path: string, paramsObj: Partial<RouteParamsType>) =>
	path.replace(/:([a-zA-Z]+)/g, (match, paramName) => {
		if (
			typeof paramName === 'string' &&
			!!Object.keys(paramsObj).length &&
			paramName in paramsObj &&
			paramsObj[paramName as keyof RouteParamsType] !== undefined
		) {
			return paramsObj[paramName as keyof RouteParamsType] ?? match;
		}
		return match;
	});
