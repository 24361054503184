import type { ButtonVariant } from '../Button/Button.helper';

export interface DefaultTableData {
	_id: string;
}

export interface ITableColumn<E> {
	key: number | string;
	dataIndex?: keyof E;
	title?: string | React.ReactNode;
	width?: number;
	render?: (props: { row: E; column: ITableColumn<E> }) => React.ReactNode;
	fixed?: boolean;
	hideColumn?: boolean;
	onSearch?: (data: Record<string, string>) => void;
}

export interface ITableHeader<E> {
	columns: ITableColumn<E>[];
	withCheckbox: boolean;
	checkedItems: E[];
	dataLength: number;
	isLoading?: boolean;
	useCheckBox?: boolean;
	onColumnCheckToggle: () => void;
}

export interface ITableBody<E> {
	data?: E[];
	columns: ITableColumn<E>[];
	onRowClick?: (rowItem: E) => void;
	emptyDataText?: string;
	withCheckbox?: boolean;
	checkedItems: E[];
	useCheckBox?: boolean;
	onRowCheckToggle?: (rowItem: E) => void;
}

export interface ITableRowCell<E> {
	column: ITableColumn<E>;
	item: E;
	style?: React.CSSProperties;
	className?: string;
}

export interface ITable<E> {
	data?: E[];
	isLoading?: boolean;
	columns: ITableColumn<E>[];
	onRowClick?: (rowItem: E) => void;
	emptyDataText?: string;
	onCheckboxChange?: (checkedItems: E[]) => void;
}

export function getLastIndexOfFixedColumn<T>(columns: ITableColumn<T>[]) {
	return columns.findLastIndex((column) => column.fixed);
}

export interface ITableActionPopover {
	actionItems: { title: string; onClick?: () => void; isLoading?: boolean }[];
	isLoading?: boolean;
	disabled?: boolean;
	triggerVariant?: ButtonVariant;
}

export const getInitialFixedLeftPos = (useCheckBox?: boolean) => (useCheckBox ? 40 : 0);
