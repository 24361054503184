import { DeleteInvoiceModal } from '@/features/invoices/components';
import UpdateFeaturedNotes from '@/features/site-management/flash-notes/components/UpdateFeaturedNotes';
import UpdateFlashMessage from '@/features/site-management/flash-notes/components/UpdateFlashMessage';
import EditSupportLine from '@/features/site-management/other/EditSupportLine';
import ViewEditAssetRate from '@/features/site-management/rate/components/ViewEditAssetRate';
import EditReferralForm from '@/features/site-management/referral/EditReferralForm';
import AddOrRemoveFiatBalance from '@/features/user-management/users/[userId]/userActions/AddOrRemoveFiatBalance';
import AddOrRemoveUserPointsBalance from '@/features/user-management/users/[userId]/userActions/AddOrRemoveUserPointsBalance';
import AddProfileNote from '@/features/user-management/users/[userId]/userActions/AddProfileNote';
import DeleteUser from '@/features/user-management/users/[userId]/userActions/DeleteUser';
import RemoveProfileNote from '@/features/user-management/users/[userId]/userActions/RemoveProfileNote';
import ApproveOrRejectWithdrawalModal from '@/features/withdrawals/components/ApproveOrRejectWithdrawalModal';

export const modalConfig = {
	initial: 'initialModal',
	testModal: 'test_modal',
	approveOrRejectWithdrawal: 'approveOrRejectWithdrawal',
	removeProfileNote: 'removeProfileNote',
	addProfileNote: 'addProfileNote',
	addRemoveUserPointBalance: 'addRemoveUserPointBalance',
	deleteSingleUser: 'deleteSingleUser',
	addRemoveFiatBalance: 'addRemoveFiatBalance',
	deleteInvoice: 'deleteInvoice',
	viewEditCryotoAsset: 'viewEditCryotoAsset',
	editReferral: 'editReferral',
	editSupportLine: 'editSupportLine',
	updateFlashMessage: 'updateFlashMessage',
	updateFeaturedNote: 'updateFeaturedNote',
} as const;

export type ModalConfigKeyType = keyof typeof modalConfig;
export type ModalConfigType = typeof modalConfig;
export type ModalConfigIdType = ModalConfigType[ModalConfigKeyType];
export interface ModalConfigStateType {
	id: ModalConfigIdType;
	header?: string;
	wrapperClassName?: string;
	metadata?: unknown;
}

export const manuallyTriggeredModals: ModalConfigIdType[] = ['test_modal'];

export const modalContent: Record<ModalConfigIdType, React.ReactNode> = {
	initialModal: null,
	test_modal: null,
	approveOrRejectWithdrawal: <ApproveOrRejectWithdrawalModal />,
	removeProfileNote: <RemoveProfileNote />,
	addProfileNote: <AddProfileNote />,
	addRemoveUserPointBalance: <AddOrRemoveUserPointsBalance />,
	deleteSingleUser: <DeleteUser />,
	addRemoveFiatBalance: <AddOrRemoveFiatBalance />,
	deleteInvoice: <DeleteInvoiceModal />,
	viewEditCryotoAsset: <ViewEditAssetRate />,
	editReferral: <EditReferralForm />,
	editSupportLine: <EditSupportLine />,
	updateFlashMessage: <UpdateFlashMessage />,
	updateFeaturedNote: <UpdateFeaturedNotes />,
};

export const closeModalById = (modalId: ModalConfigIdType) => {
	const modalCloseBtn = document.getElementById(`closeBtnRef-${modalId}`) as HTMLButtonElement | null;
	if (!modalCloseBtn) return;
	modalCloseBtn.click();
};
